<template>
  <div class="studio-page">
    <div class="container text-center" v-if="isLoading">
      <HMSpinner class="mt-4" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>{{ $t('STUDIO.LABELS.BROWSE_OUR_SHOP') }}</h1>
        </div>
      </div>
      <div class="row" v-if="!isLoading">
        <div class="col-12 products-list">
          <div class="row">
            <div class="col-12 d-flex justify-content-end align-items-center">
              <NuxtLink
                v-if="hasStripe"
                class="purchase-gift-tab"
                :to="{ name: 'studio-gifts' }"
              >
                <span class="d-none d-md-inline">
                  {{ $t('STUDIO.LINKS.PURCHASE_A_GIFT') }}
                </span>
                <i class="far fa-gift d-md-none"></i>
              </NuxtLink>
            </div>
            <div class="col-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 col-md-9 d-flex justify-content-between mb-2 mb-md-0"
            >
              <div class="d-flex flex-wrap">
                <NuxtLink
                  v-for="tag in productTags"
                  class="undecorated-router-link"
                  :key="tag.id"
                  :to="{ query: { tag: encodeURIComponent(tag.name) } }"
                  replace
                >
                  <HMProductTag class="mt-2 me-2" :selected="tag.selected">
                    {{ tag.name }}
                  </HMProductTag>
                </NuxtLink>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <HMSearch v-model="search" />
            </div>
          </div>
          <div class="row px-2" v-if="filteredProducts.length">
            <div
              class="col-6 col-md-3 mt-4 px-1 px-md-3"
              v-for="product in filteredProducts"
              :key="product.id"
            >
              <NuxtLink
                class="undecorated-router-link"
                :to="{
                  name: 'studio-product',
                  params: { product_id: product.id },
                }"
              >
                <StudioProductCard :product="product" size="sm" />
              </NuxtLink>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12 text-center my-5">
              <h4 class="c-dark-gray">
                {{ $t('STUDIO.TEXT.NO_PRODUCTS_MATCH_THIS_FILTER') }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  name: 'studio-shop',

  isStudio: true,
  hideInstagramSlider: true,
})
</script>

<script lang="ts">
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores';
import StudioProductCard from '@/components/products/StudioProductCard.vue';
import HMSearch from '@/components/common/HMSearch.vue';
import HMProductTag from '@/components/common/HMProductTag.vue';

export default {
  name: 'StudioShop',
  components: {
    StudioProductCard,
    HMSearch,
    HMProductTag,
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
      hasStripe: 'hasStripe',
    }),
    searchedProducts() {
      if (!this.search) return this.studio.products;
      return this.studio.products.filter(
        product =>
          product.product_name
            .toUpperCase()
            .indexOf(this.search.toUpperCase()) !== -1
      );
    },
    productTags() {
      if (!this.searchedProducts?.length) return [];
      const allProductsTag = {
        id: 0,
        name: this.$t('STUDIO.LABELS.ALL_PRODUCTS'),
      };
      const foundTags = {
        0: {
          ...allProductsTag,
          selected: allProductsTag.name === this.selectedProductTag,
        },
      };
      this.searchedProducts.forEach(p => {
        if (p.tags?.length) {
          p.tags.forEach(t => {
            foundTags[t.id] = {
              ...t,
              selected: t.name === this.selectedProductTag,
            };
          });
        }
      });
      return Object.values(foundTags);
    },
    selectedProductTag() {
      return decodeURIComponent(
        this.$route.query.tag || this.$t('STUDIO.LABELS.ALL_PRODUCTS')
      );
    },
    filteredProducts() {
      if (this.selectedProductTag === this.$t('STUDIO.LABELS.ALL_PRODUCTS'))
        return this.searchedProducts;
      return this.searchedProducts.filter(product =>
        product.tags.some(t => t.name === this.selectedProductTag)
      );
    },
    nonPurchasedProducts() {
      if (this.selectedProductTag === this.$t('STUDIO.LABELS.ALL_PRODUCTS'))
        return this.searchedProducts.filter(product => !product.purchased);
      return this.searchedProducts.filter(
        product =>
          !product.purchased &&
          product.tags.some(t => t.name === this.selectedProductTag)
      );
    },
    purchasedProducts() {
      return this.filteredProducts.filter(product => product.purchased);
    },
  },
  data() {
    return {
      isLoading: false,
      search: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.purchase-gift-tab {
  display: flex;
  justify-content: space-between;
  margin: 0 0 1px;
  color: var(--brand-color, $coral);
}
</style>
